import React from "react";

const CNMLogo = ({fill}) => (
  <>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1201.34 442.81"
      fill={fill? fill : '#cc0000'}
    >
      <title>CNM</title>
      <path
        d="M245.26,445.08Q140.8,445.08,76.13,377q-60.4-63.26-60.4-155.11T76.84,70.38Q140.08,7.74,245.26,7.73h85.27V57.54H245.26q-81.73,0-129.69,46.38t-48,117.94q0,72.15,46.91,121.27,49.72,52.14,130.75,52.14h85.27v49.81Zm0-67.42q-72.48,0-117.25-44.35Q86.07,291.4,86.08,227.62T128,121.32q44.76-46.15,117.25-46.16h85.27V125H245.26q-49,0-78.17,29.88T138,227.61q0,43.49,29.13,71.86t78.17,28.38h85.27v49.81Z"
        transform="translate(-15.73 -7.73)"
      />
      <path
        d="M408.65,69.11q-10.35-11.56-20-11.57-4.83,0-4.83,9.7V445.08h-53.3V67.17q0-24.26,13.5-40.64Q359,7.75,387.38,7.73q36.24,0,59,27.94L599.85,224V7.73h51.88V363.09ZM668.71,450.55q-35.6,0-61.93-32.09L454.18,230.15V445.08h-53.3V88.79Q483.35,190.54,649,389.14q9.56,11.61,14.35,11.6,3.42,0,5.47-14.6.68-7.92.69-21.3l9.55-174.74q8.3-46,16.61-92,8.91-9.69,18.82-19.64,6.46-6.47,12.82-12.5c-2.24,168.91-4.72,291.84-4.51,320.77,0,5.45.25,24.93-11.39,42Q696.45,450.55,668.71,450.55Z"
        transform="translate(-15.73 -7.73)"
      />
      <path
        d="M1171.9,447V210.21q0-71.43-22.86-106.56-27.66-43-94.42-43-47.5,0-71,21t-34.88,76.79q-10.23-54.6-38.5-77.4Q885,60.66,838.11,60.65q-65.57,0-92.62,43.6-21.66,35.13-21.66,106L678.67,447V209.8q0-93.41,31.92-140.73,37.93-58.23,127.67-58.23,71.65,0,110.21,32.8,37.93-32.79,106.59-32.8,91.55,0,130.08,56.42,31.92,46.71,31.92,142.54V447Zm-276.43,0V209.92q0-82.44-57.21-82.45-27.71,0-42.76,27.88-11.44,23.06-11.44,54.57V447H738.89V209.47q0-52.23,22.28-88.08,26.51-43.73,77.09-43.73,43.95,0,64.44,18.83,17.46,16.4,29.51,60.74,8.41,32.21,8.43,52.24V447Zm216.8,0V209.92q0-82.44-57.21-82.45-27.7,0-42.76,27.88-11.44,23.06-11.44,54.57V447H955.69V209.47q0-21.25,9-51.63,22.88-80.18,90.33-80.18,102.38,0,102.38,131.81V447Z"
        transform="translate(-15.73 -7.73)"
      />
      <path d="M616.4,229.14" transform="translate(-15.73 -7.73)" />
    </svg>
  </>
);

export default CNMLogo;
